import React, { useRef, useState, useEffect } from "react";
import styled from "styled-components";
import { selectedBoothColorProps } from "pages";
import Arrow from "../../../assets/pages/Index/Arrow.svg";

type BoothColorProps = {
  activeArea: "Curtain" | "Wall" | "Frame";
  selectedBoothColor: selectedBoothColorProps;
  handleSetColor: (color: string) => void;
  liftSwipe: { [areaName: string]: boolean };
  visibleSwipe: boolean;
};

const Container = styled.div`
  width: 100vw;
  position: fixed;
  bottom: 0px;
  left: 0px;
  overflow-x: scroll;
  overflow-y: hidden;
`;

const Wrapper = styled.div`
  width: fit-content;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
`;

const ColorBox = styled.div<any>`
  position: relative;
  height: ${props => (props.active ? "72px" : "56px")};
  width: ${props => (props.active ? "72px" : "56px")};
  background: ${props => props.bgColor};
  cursor: pointer;
`;

const DragContainer = styled.div<any>`
  position: fixed;
  bottom: ${props => (props.lift ? "90px" : "65px")};
  right: 17px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
`;

const DragForMoreColors = styled.p<any>`
  display: ${props => (props.isVisible ? "block" : "none")};
  font-size: 20px;
  margin: 0px 10px;
  margin: 0px 10px;
  user-select: none;
  @media only screen and (max-width: 1300px) {
    display: block;
  }
`;

const ArrowImage = styled.img<any>`
  display: ${props => (props.isVisible ? "block" : "none")};
  width: 40px;
  user-select: none;
  @media only screen and (max-width: 1300px) {
    display: block;
  }
`;

const AllBoothColors = [
  {
    value: "#2D77B3",
    label: "FINE BLUE"
  },
  {
    value: "#88AEDC",
    label: "DRESDEN BLUE"
  },
  {
    value: "#97ACD2",
    label: "BALI BLUE"
  },
  {
    value: "#A4BED8",
    label: "AZUR"
  },
  {
    value: "#C7E8F5",
    label: "ICE"
  },
  {
    value: "#CCE5EF",
    label: "ARA"
  },
  {
    value: "#204267",
    label: "FORD FACTORY"
  },
  {
    value: "#6D879D",
    label: "BALTIC"
  },
  {
    value: "#C1D6E2",
    label: "BLUE ICE"
  },
  {
    value: "#E8F1EF",
    label: "LOUD"
  },
  {
    value: "#F9FCF7",
    label: "DAZZLING WHITE"
  },
  {
    value: "#F6FBF9",
    label: "AQUA"
  },
  {
    value: "#394A3E",
    label: "DARK GREEN"
  },
  {
    value: "#9AC0BF",
    label: "CLEAR TEAL"
  },
  {
    value: "#E2F2EC",
    label: "OPALINE"
  },
  {
    value: "#C2E1BD",
    label: "MIAMI"
  },
  {
    value: "#DDE9C9",
    label: "PISTACIO"
  },
  {
    value: "#FEFDEC",
    label: "ASPARAGUS"
  },
  {
    value: "#385343",
    label: "LINDEN GREEN"
  },
  {
    value: "#89AD45",
    label: "GREEN LEAF"
  },
  {
    value: "#B9CC98",
    label: "CACTUS"
  },
  {
    value: "#D9E9B8",
    label: "SPRING"
  },
  {
    value: "#FBFDD9",
    label: "LIME"
  },
  {
    value: "#FBF9D7",
    label: "CHAMPAGNE"
  },
  {
    value: "#F1D579",
    label: "CLASSIC YELLOW"
  },
  {
    value: "#FBE958",
    label: "FRESH YELLOW"
  },
  {
    value: "#FDF7BA",
    label: "PALE GOLD"
  },
  {
    value: "#FEFBDE",
    label: "SUNNY"
  },
  {
    value: "#FDFCE8",
    label: "PONGEE"
  },
  {
    value: "#FDFBE3",
    label: "CHIFFON"
  },
  {
    value: "#EB5639",
    label: "ORANGE"
  },
  {
    value: "#ED7E4F",
    label: "CLEMENTINE"
  },
  {
    value: "#EF8E35",
    label: "YELLOW ORANGE"
  },
  {
    value: "#FADFA9",
    label: "CURRY"
  },
  {
    value: "#FCEBC2",
    label: "MELBA"
  },
  {
    value: "#FCF2CD",
    label: "BEACHSHELL"
  },
  {
    value: "#AB2732",
    label: "POST OFFICE RED"
  },
  {
    value: "#DA3837",
    label: "CHINESE RED"
  },
  {
    value: "#F3AF91",
    label: "CORAL"
  },
  {
    value: "#F3CAAC",
    label: "SALMON"
  },
  {
    value: "#F8DDCA",
    label: "ISLAND BLUSH"
  },
  {
    value: "#FBE5D8",
    label: "CALYPSO"
  },
  {
    value: "#5E6360",
    label: "SLATE GREY"
  },
  {
    value: "#433732",
    label: "CHOCOLATE"
  },
  {
    value: "#C0C1B1",
    label: "SILICA"
  },
  {
    value: "#ECDFBC",
    label: "CREAMY"
  },
  {
    value: "#FEFDEC",
    label: "DAWN"
  },
  {
    value: "#FEFEF8",
    label: "ALABASTER"
  },
  {
    value: "#161616",
    label: "BLACK"
  },
  {
    value: "#3C413F",
    label: "FLAT BLACK"
  },
  {
    value: "#7B8892",
    label: "LIGHT GREY"
  },
  {
    value: "#B8BCC6",
    label: "ALUMINIUM"
  },
  {
    value: "#DAE0D8",
    label: "FASHION GREY"
  },
  {
    value: "#FEFDF5",
    label: "SNOW WHITE"
  },
  {
    value: "#D675AF",
    label: "ROSE"
  },
  {
    value: "#E27FA0",
    label: "JUICY PINK"
  },
  {
    value: "#F1ECED",
    label: "ORCHID"
  },
  {
    value: "#F8FBF4",
    label: "RAIN"
  },
  {
    value: "#FFFFFF",
    label: "WHITE"
  },
  {
    value: "#C12826",
    label: "VERMILLION"
  },
  {
    value: "#F9D448",
    label: "SUPER YELLOW"
  },
  {
    value: "#244C23",
    label: "LARCH GREEN"
  },
  {
    value: "#153527",
    label: "DEEP GREEN"
  },
  {
    value: "#232960",
    label: "PURE BLUE"
  },
  {
    value: "#272C68",
    label: "SUPER DARK BLUE"
  },
  {
    value: "#1C1926",
    label: "DARK BLUE"
  },
  // {
  //   value: "#E3617B",
  //   label: "VERMILLION MIX"
  // },
  // {
  //   value: "#FBE472",
  //   label: "SUPER YELLOW MIX"
  // },
  // {
  //   value: "#6EC2A3",
  //   label: "LARCH GREEN MIX"
  // },
  // {
  //   value: "#72C7B7",
  //   label: "DEEP GREEN MIX"
  // },
  // {
  //   value: "#429AD4",
  //   label: "PURE BLUE MIX"
  // },
  // {
  //   value: "#79A0D4",
  //   label: "SUPER DARK BLUE MIX"
  // },
  // {
  //   value: "#3874A7",
  //   label: "DARK BLUE MIX"
  // }
];

const CurtainColors = [
  {
    value: "#FFFEE6",
    label: "EGG WHITE"
  },
  {
    value: "#FED330",
    label: "MUSTARD"
  },
  {
    value: "#DD2F4D",
    label: "POPPY"
  },
  {
    value: "#558495",
    label: "JEAN"
  },
  {
    value: "#2477DD",
    label: "ARCTIC"
  },
  {
    value: "#DC2E3D",
    label: "CANDY"
  },
  {
    value: "#EB6642",
    label: "ORANGE"
  },
  {
    value: "#9CC07A",
    label: "PUFF"
  },
  {
    value: "#F1C94D",
    label: "PARMESAN"
  },
  {
    value: "#54596E",
    label: "LEAD"
  },
  {
    value: "#272727",
    label: "PEBBLE"
  },
  {
    value: "#9F8967",
    label: "DUNE"
  },
  {
    value: "#1E1811",
    label: "FINN"
  },
  {
    value: "#1D347C",
    label: "ARTEK"
  },
  {
    value: "#A399BC",
    label: "LAV"
  },
  {
    value: "#F3EEEA",
    label: "SNOW"
  },
  {
    value: "#F9FAFA",
    label: "WHITE"
  },
  {
    value: "#E7DECF",
    label: "BONE"
  },
  {
    value: "#E7DCCB",
    label: "DOVER"
  },
  {
    value: "#9A8C79",
    label: "CLAY"
  },
  {
    value: "#453A38",
    label: "UMBER"
  },
  {
    value: "#EDD596",
    label: "PINE"
  },
  {
    value: "#8AD3C1",
    label: "JUNGLE"
  },
  {
    value: "#339E8C",
    label: "JADE"
  },
  {
    value: "#D16059",
    label: "BLUSH"
  },
  {
    value: "#921639",
    label: "RUBY"
  }
];

const DragText = ({
  activeArea,
  handleButtonPress,
  handleButtonRelease,
  lift,
  visibleSwipe
}: any) => {
  return (
    <>
      <DragContainer
        onTouchStart={handleButtonPress}
        onTouchEnd={handleButtonRelease}
        onMouseDown={handleButtonPress}
        onMouseUp={handleButtonRelease}
        onMouseLeave={handleButtonRelease}
        lift={lift}
      >
        {visibleSwipe && (
          <DragForMoreColors isVisible={activeArea !== "Curtain"}>
            Swipe for more colors
          </DragForMoreColors>
        )}
        <ArrowImage src={Arrow} isVisible={activeArea !== "Curtain"} />
      </DragContainer>
    </>
  );
};

export const BoothColor = ({
  activeArea,
  selectedBoothColor,
  handleSetColor,
  liftSwipe,
  visibleSwipe
}: BoothColorProps) => {
  const refs = useRef<any>();
  const [count, setCount] = useState(0);
  const [intervalState, setIntervalState] = useState<any>();
  const [startDrag, setStartDrag] = useState(false);
  const [state, setState] = useState({
    isScrolling: false,
    clientX: 0,
    scrollX: 0
  });

  const checkActive = (
    selectedColor: selectedBoothColorProps,
    boxColor: string,
    activeArea: "Curtain" | "Wall" | "Frame"
  ) => {
    if (selectedColor[activeArea] === boxColor) {
      return true;
    } else {
      return false;
    }
  };

  const onMouseDown = (e: any) => {
    setStartDrag(false);
    e.persist();
    setState((prev: any) => {
      let tempPrev = { ...prev };
      tempPrev["isScrolling"] = true;
      tempPrev["clientX"] = e.clientX;
      return tempPrev;
    });
  };

  const onMouseUp = () => {
    setState((prev: any) => {
      let tempPrev = { ...prev };
      tempPrev["isScrolling"] = false;
      return tempPrev;
    });
  };

  const onMouseMove = (e: any) => {
    e.persist();
    if (state.isScrolling) {
      setState((prev: any) => {
        let tempPrev = { ...prev };
        const { clientX, scrollX } = tempPrev;
        refs.current.scrollLeft = clientX - scrollX - e.clientX;
        if (tempPrev["scrollX"] < 0) {
          tempPrev["scrollX"] = scrollX + e.clientX - clientX;
          tempPrev["clientX"] = e.clientX;
        }
        return tempPrev;
      });
    }
  };

  const onScroll = (e: any) => {
    setState((prev: any) => {
      let tempPrev = { ...prev };
      tempPrev["scrollX"] = -refs.current.scrollLeft;
      return tempPrev;
    });
  };

  const handleButtonPress = () => {
    setCount(0);
    let counts = 0;
    setIntervalState(
      setInterval(() => {
        counts++;
        setCount(counts);
        setState((prev: any) => {
          let tempPrev = { ...prev };
          const { scrollX } = state;
          refs.current.scrollLeft = 0 - scrollX + counts;
          tempPrev["scrollX"] = scrollX + counts;
          tempPrev["clientX"] = 0;
          return tempPrev;
        });
      }, 1)
    );
  };

  const handleButtonRelease = () => {
    setTimeout(() => {
      clearTimeout(intervalState);
    }, 400);
  };

  return (
    <>
      <Container
        ref={refs}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        onMouseMove={onMouseMove}
        onScroll={onScroll}
      >
        <Wrapper>
          {activeArea !== "Curtain"
            ? AllBoothColors.map((colorItems: any) => (
                <div
                  style={{
                    position: "relative",
                    backgroundColor: "transparent",
                    paddingTop: "50px"
                  }}
                >
                  <ColorBox
                    bgColor={colorItems.value}
                    active={checkActive(
                      selectedBoothColor,
                      colorItems.value,
                      activeArea
                    )}
                    onClick={() => {
                      if (!startDrag) {
                        handleSetColor(colorItems.value);
                      }
                    }}
                  />
                  {checkActive(
                    selectedBoothColor,
                    colorItems.value,
                    activeArea
                  ) && (
                    <p
                      style={{
                        bottom: "75px",
                        width: "100%",
                        position: "absolute",
                        fontSize: "16px",
                        textAlign: "center",
                        margin: "0px"
                      }}
                    >
                      {colorItems.label}
                    </p>
                  )}
                </div>
              ))
            : CurtainColors.map((colorItems: any) => (
                <div
                  style={{
                    position: "relative",
                    backgroundColor: "transparent",
                    paddingTop: "50px"
                  }}
                >
                  <ColorBox
                    bgColor={colorItems.value}
                    active={checkActive(
                      selectedBoothColor,
                      colorItems.value,
                      activeArea
                    )}
                    onClick={() => {
                      if (!startDrag) {
                        handleSetColor(colorItems.value);
                      }
                    }}
                  />
                  {checkActive(
                    selectedBoothColor,
                    colorItems.value,
                    activeArea
                  ) && (
                    <p
                      style={{
                        bottom: "75px",
                        width: "100%",
                        position: "absolute",
                        fontSize: "16px",
                        textAlign: "center",
                        margin: "0px"
                      }}
                    >
                      {colorItems.label}
                    </p>
                  )}
                </div>
              ))}
        </Wrapper>
      </Container>
      <DragText
        activeArea={activeArea}
        handleButtonPress={handleButtonPress}
        handleButtonRelease={handleButtonRelease}
        lift={liftSwipe[activeArea]}
        visibleSwipe={visibleSwipe}
      />
    </>
  );
};
