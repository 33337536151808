import React, { useState } from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";

import { BoothSample } from "../components/pages/Index/BoothSample";
import { BoothArea } from "../components/pages/Index/BoothArea";
import { BoothColor } from "../components/pages/Index/BoothColor";
import Page from "../components/Page";
import Container from "../components/Container";
import IndexLayout from "../layouts";
import "../styles/fontstyle.css";
import { BackgroundImage } from "../components/pages/Index/Background";

export type selectedBoothColorProps = {
  Frame: string;
  Wall: string;
  Curtain: string;
};

const StyledContainer = styled(Container)`
  @media only screen and (max-width: 576px) {
    height: calc(100vh - 50px - 72px - 48px);
    display: flex;
    align-items: flex-end;
    padding-bottom: 32px;
  }
`;

const defaultBoothColor = {
  Frame: "#2B4A9F",
  Wall: "#4B87C7",
  Curtain: "#204267"
};

const IndexPage = (props: any) => {
  const [activeArea, setActiveArea] = useState<any>("Frame");
  const [visibleSwipe, setVisibleSwipe] = useState<boolean>(true);
  const [liftSwipe, setLiftSwipe] = useState<any>({
    Frame: false,
    Wall: false,
    Curtain: false
  });
  const [selectedBoothColor, setSelectedBoothColor] = useState<
    selectedBoothColorProps
  >(defaultBoothColor);

  const handleSetColor = (color: string) => {
    setSelectedBoothColor((prev: any) => {
      let tempPrev = { ...prev };
      tempPrev[activeArea] = color;
      return tempPrev;
    });
    setVisibleSwipe(false);
    setLiftSwipe((prev: any) => ({ ...prev, [activeArea]: true }));
  };

  return (
    <IndexLayout urlParam={props.location}>
      <Helmet>
        <meta name="viewport" content="initial-scale=1, viewport-fit=cover" />
        <meta name="viewport" content="width=device-width, user-scalable=no" />
      </Helmet>
      <BackgroundImage page={props.location} />
      <Page>
        {/* <StyledContainer> */}
          <BoothSample
            selectedBoothColor={selectedBoothColor}
            setActiveArea={setActiveArea}
          />
          <BoothArea activeArea={activeArea} setActiveArea={setActiveArea} />
          <BoothColor
            activeArea={activeArea}
            selectedBoothColor={selectedBoothColor}
            handleSetColor={handleSetColor}
            liftSwipe={liftSwipe}
            visibleSwipe={visibleSwipe}
          />
        {/* </StyledContainer> */}
      </Page>
    </IndexLayout>
  );
};

export default IndexPage;
