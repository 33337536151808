import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useSpring, animated } from "react-spring";
import Frame from "../../../assets/pages/Index/Frame.svg";
import Wall from "../../../assets/pages/Index/Wall.svg";
import Curtain from "../../../assets/pages/Index/Curtain.svg";
import "../../../styles/bootharea.css";

type BoothAreaConfigProps = {
  engTitle: string;
  thTitle: string;
  img: any;
};

type AreaProps = {
  engTitle: string;
  thTitle: string;
  img: any;
  active: boolean;
  setActiveArea: (areaName: string) => void;
};

type BoothAreaProps = {
  activeArea: string;
  setActiveArea: (areaName: string) => void;
};

const Container = styled.div`
  position: fixed;
  right: 0px;
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  top: calc((100vh - 400px) / 2);
  @media only screen and (max-width: 576px) {
    top: 0px;
  }
`;

const Header = styled.h3`
  font-size: 24px;
  font-weight: 500;
  width: 126px;
  text-align: center;
  margin: 0px;
  margin-right: 10px;

  @media only screen and (max-width: 576px) {
    display: none;
  }
`;

const AreaContainer = styled.div`
  width: fit-content;
  height: fit-content;
  border-radius: 10px 0px 0px 10px;
  overflow: hidden;
  background: white;
  box-shadow: 0px 4px 20px 4px rgba(184, 184, 184, 0.3);
  margin-top: 16px;
  cursor: pointer;
`;

const Wrapper = styled(animated.div)<any>`
  height: 99px;
  border-left: ${props => (props.active ? "0px" : "8px solid #ef602b")};
  display: flex;
  justify-content: flex-end;
  align-items: center;
  @media only screen and (max-width: 576px) {
    height: 79px;
  }
`;

const AreaName = styled.div`
  width: 54px;
  font-size: 20px;
  color: #ef602b;
  user-select: none;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  @media only screen and (max-width: 576px) {
    font-size: 16px;
  }
`;

const BoothAreaImage = styled.img`
  width: 51px;
  height: 67px;
  @media only screen and (max-width: 576px) {
    width: 40.7px;
    height: 53.5px;
  }
`;

const EngSpan = styled.span`
  font-size: 20px;
  @media only screen and (max-width: 576px) {
    font-size: 16px;
  }
`;

const ThSpan = styled.span`
  font-size: 16px;
  font-family: Kanit;
  @media only screen and (max-width: 576px) {
    font-size: 12px;
  }
`;

const AreaNameBox = styled.div`
  margin-right: 10px;
  @media only screen and (max-width: 576px) {
    margin-right: 0px;
  }
`;

const BoothAreaConfig = [
  {
    engTitle: "Frame",
    thTitle: "กรอบ",
    img: Frame
  },
  {
    engTitle: "Wall",
    thTitle: "กำแพง",
    img: Wall
  },
  {
    engTitle: "Curtain",
    thTitle: "ผ้าม่าน",
    img: Curtain
  }
];

const Area = ({ engTitle, thTitle, img, active, setActiveArea }: AreaProps) => {
  const [activeStyle, setActiveStyle] = useState({
    width: "155px",
    paddingRight: "20px"
  });
  const [inActiveStyle, setInActiveStyle] = useState({
    width: "99px",
    paddingRight: "23px"
  });
  const springStyles = useSpring({
    width: active ? activeStyle.width : inActiveStyle.width,
    paddingRight: active
      ? inActiveStyle.paddingRight
      : inActiveStyle.paddingRight,
    config: { duration: 200 }
  });

  const handleResize = () => {
    if (window.innerWidth <= 576) {
      setActiveStyle({
        width: "124px",
        paddingRight: "19.9px"
      });
      setInActiveStyle({
        width: "79px",
        paddingRight: "12.8px"
      });
    } else {
      setActiveStyle({
        width: "155px",
        paddingRight: "20px"
      });
      setInActiveStyle({
        width: "99px",
        paddingRight: "23px"
      });
    }
  };

  useEffect(() => {
    if (typeof window !== undefined) {
      window.addEventListener("resize", handleResize);
      handleResize();
    }
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <AreaContainer>
        <Wrapper
          active={!active}
          onClick={() => setActiveArea(engTitle)}
          style={springStyles}
        >
          {active && (
            <AreaNameBox>
              <AreaName>
                <EngSpan>{engTitle}</EngSpan>
                <ThSpan>{thTitle}</ThSpan>
              </AreaName>
            </AreaNameBox>
          )}
          <BoothAreaImage src={img} />
        </Wrapper>
      </AreaContainer>
    </>
  );
};

export const BoothArea = ({ activeArea, setActiveArea }: BoothAreaProps) => {
  return (
    <>
      <Container>
        <Header>Select the area to apply color</Header>
        {BoothAreaConfig.map((boothData: BoothAreaConfigProps) => (
          <Area
            engTitle={boothData.engTitle}
            thTitle={boothData.thTitle}
            img={boothData.img}
            active={boothData.engTitle === activeArea}
            setActiveArea={setActiveArea}
          />
        ))}
      </Container>
    </>
  );
};
